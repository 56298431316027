<template>
  <layout-full v-if="this.$store.state.auth.isLoadingMe == false">
    <router-view />
  </layout-full>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'
import store from '@/store'

export default {
  components: {
    LayoutFull,
  },
}
</script>
